<template>
    <div class=" d-flex col-12 p-0 m-0" v-if="promotionForm">
        <!-- /card-header -->
        <div class="flex-fill d-flex flex-column card-body p-3 pt-0" v-loading="isLoading">
            <div>
                <el-form :model="promotionForm" :rules="rules" ref="promotionForm">
                    <!--Title-->
                    <el-form-item prop="title">
                        <el-input type="input" style="width:100%"
                                  :autosize="{ minRows: 2}"
                                  placeholder="Input tilte"
                                  v-model="promotionForm.title">
                        </el-input>
                    </el-form-item>
                    <!--Select type-->
                    <el-form-item prop="type" >
                        <el-select class="p-0" v-model="promotionForm.type" placeholder="Choose a type" style="width:100%">
                            <el-option v-for="item in typeSelect"
                                       :key="item.value"
                                       :label="item.label"
                                       :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <!--Select reciptein type-->
                    <el-form-item  prop="recipientType">
                        <el-select class="p-0" v-model="promotionForm.recipientType" placeholder="Who will get this notification"  style="width:100%">
                            <el-option v-for="item in recipientSelect"
                                       :key="item.value"
                                       :label="item.label"
                                       :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <!--Reciptein list-->
                    <el-form-item class="d-flex justify-content-center" prop="recipient" v-if="promotionForm.recipientType===2 || promotionForm.recipientType===3">
                        <el-input type="textarea" style="width:27rem;" class="col-12"
                                  :autosize="{ minRows: 2}"
                                  placeholder="Recipients list here! ex:gatruilongvn, hungtt, thanhhung,..."
                                  v-model="promotionForm.recipient">
                        </el-input>
                    </el-form-item>
                    <!-- Submit -->
                    <el-form-item class="d-flex justify-content-center">
                        <el-button  style="width:100%" class="col-12 pl-5 pr-5"
                                   @click="saveChange('promotionForm')"
                                   type="primary"
                                   round>Save</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <!-- /.card-body -->
    </div>
</template>
<script>
    import promotApi from '@/api/common/promotionNotification';
    import commonAct from '@/commonActionHandle.js';
    import baseComponent from "@/scripts/baseComponent";
    export default {
        extends: baseComponent,
        data() {
            return {
                rules: {
                    title: [
                        {
                            required: true,
                            message: "Title cannot blank",
                            trigger: "blur"
                        }
                    ],
                    type: [
                        {
                            required: true,
                            message: "Choose a type",
                            trigger: "blur"
                        }
                    ],
                    recipientType: [
                        {
                            required: true,
                            message: "Atleast 1 recipteint plz",
                            trigger: "blur"
                        }
                    ],
                    recipient: [
                        {
                            
                            required: true,
                            message: "input recipeint list",
                            trigger: "blur"
                        }
                    ]
                },
                typeSelect: [
                    {
                        value: 1,
                        label: 'Announce'
                    },
                    {
                        value: 2,
                        label: 'Promotion'
                    },
                    {
                        value: 3,
                        label: 'With link redirec'
                    },
                    {
                        value: 4,
                        label: 'Popup one time'
                    },
                    //{
                    //    value: 5,
                    //    label: 'DashBoad'
                    //},
                    //{
                    //    value: 6,
                    //    label: 'Email notification'
                    //},
                ],
                recipientSelect: [
                    {
                        value: 1,
                        label: 'Everyone'
                    },
                    {
                        value: 2,
                        label: 'Username list'
                    },
                    {
                        value: 3,
                        label: 'Email list'
                    },
                    //{
                    //    value: 4,
                    //    label: 'User by filter'
                    //},
                ]

            }
        },
        methods: {
            saveChange(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        promotApi.savePromotion(this.promotionForm).then((res) => {
                            if (res.data.result == 0 && res.data.data !== undefined) {
                               
                                this.$emit("SaveSettingSuccess", res.data.data);

                            } else {
                                commonAct.showError(res.data.message);
                            }
                        }).catch((err) => {
                            commonAct.showError(err);
                        });
                    } else {
                        return false;
                    }
                });
               
            }
        },
        components: {
        },
        props: {
            currentCommand: {
                type: String,
                default: ""
            },
            promotionForm: {
                type: Object,
                default: null
            }
        },
        watch: {
           
        }
    }
</script>